/**
 * @package jetpack portfolio
 * 
 */

!(function($) {
    'use strict';

    // DOM Ready
    $(function() {
        
    })
})(jQuery)

module.exports = {};